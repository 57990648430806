import React from "react"
import Layout from "../components/Layout"
import topPic from "../images/mcmillan.jpg"
import bottomPic from "../images/pagoda.jpg"
import article from "../images/article.jpg"
import ContactBlock from "../components/contactBlock"
import superlawyer from "../images/super-lawyer-10.png"

const Qualifications = () => {
  return (
    <Layout>
      <div className="page">
        <div className="min-hero-block">
          <div className="sub">
            <h1>Qualifications</h1>
          </div>
        </div>
        <div className="main-content">
          {/* Statement of Qualifications */}
          <div className="block">
            <h2>Statement of Qualifications</h2>
            <div className="block-sub wrap">
              {/* <div className="p-block top"> */}
              <div className="img-block right">
                <img src={topPic} alt="" />
                <div className="cred">McMillan Park in Washington DC</div>
              </div>
              <p>
                Established in 1992, the Law Offices of Andrea Ferster is a
                public interest law office that focuses on land use, historic
                preservation, transportation advocacy, and nonprofit tax and
                corporate matters.
              </p>
              <p>
                Andrea Ferster is a recognized expert on federal and local
                historic preservation law, nonprofit tax, and trails and
                transportation policy. <br /> Andrea has helped dozens of
                organizations obtain federal tax exemption from the Internal
                Revenue Service.
              </p>
              <p>
                She represents organizations and governmental agencies
                nationwide in litigation over enforcement of historic
                preservation laws and on transportation matters. Andrea's
                long-term clients include the National Trust for Historic
                Preservation, International PNH Interest Group, and the
                Rails-to-Trails Conservancy, where she also serves as general
                counsel.{" "}
              </p>
              {/* </div> */}
            </div>

            <div className="block-sub wrap">
              <div className="img-block left">
                <img src={bottomPic} alt="" />
                <div className="cred">
                  The National Park Seminary Historic District, Forest Glen, MD
                  - Photo credit: Save Our Seminary at Forest Glen
                </div>
              </div>
              <p>
                Andrea is a member of the District of Columbia Bar, the New York
                Bar, and the bars of the U.S. Supreme Court, the U.S. Courts of
                Appeal for the District of Columbia, First, Fourth, Eleventh,
                and Federal Circuits, the U.S. Court of Federal Claims and the
                U.S. District Court for the District of Columbia.
              </p>
            </div>
          </div>
        </div>
        <div className="main-content">
          {/* The office's range of services include: */}
          <div className="block">
            <h2>The office's range of services include:</h2>
            <div className="block-sub">
              <ul>
                <li>
                  <span>Environmental litigation and advocacy</span> -
                  litigation and advocacy before courts and federal agencies on
                  highway and transit projects.
                </li>
                <li>
                  <span>Historic preservation</span> - litigation and advocacy
                  before courts and federal agencies to enforce the National
                  Historic Preservation Act.
                </li>
                <li>
                  <span>Land use</span> - advocacy before District of Columbia
                  land use planning agencies and courts on zoning, historic
                  preservation, easements, building code compliance, and
                  environmental impact issues.
                </li>
                <li>
                  <span>Tax exempt organizations</span> - assist nonprofits,
                  including "Main Street" organizations, successfully apply for
                  federal tax exemption; provide general counsel advice on a
                  wide range of nonprofit tax, corporate, fundraising, and
                  personnel matters.
                </li>
                <li>
                  <span>Trail Planning</span> - advise on legal issues affecting
                  trails and greenways, including liability issues, and Surface
                  Transportation Board proceedings relating to rail-trail
                  conversions.
                </li>
                <li>
                  <span>Constitutional "takings" jurisprudence</span> - assist
                  governments defend against Fifth Amendment "takings"
                  challenges to environmental and land use regulation.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="main-content">
          {/* Publications */}
          <div className="block">
            <h2>Publications</h2>
            <div className="block-sub">
              <ul>
                <li>
                  Ferster & Merritt, Fighting Freeways and Saving Historic
                  Roads, Forum Journal, Summer, 2000, Vol. 14, No. 4.
                </li>
                <li>
                  Ferster & Brabec, Planning for Trails and Greenways after
                  Dolan v. City of Tigard, ProBike News (Aug. 1994).
                </li>
              </ul>
              <div className="img-block right">
                <a href={article} alt="" target="_blank">
                  <img src={article} alt="" />
                </a>
              </div>
            </div>
            <div className="block-sub">
              <ul>
                <li>
                  Ferster, Securing Tax Exemption for 'Main Street'
                  Organizations: Choosing the Right Classification, Preservation
                  Law Reporter, Vol. 13, No. 3 (March, 1994).
                </li>
                <li>
                  Ferster, Congress Acts to Curb Excess Benefits by Nonprofit
                  Insiders, Preservation Law Reporter, Vol. 15, No. 9
                  (September, 1996).
                </li>
                <li>
                  Ferster, Andrea, Recent Developments in the Law Interpreting
                  the National Historic Preservation Act, in SE 31, ALI-ABA 227
                  (1999).
                </li>

                <li>
                  Ferster, Andrea, Commentary - Rails-to-Trails Conversions: A
                  Review of Legal Issues, Planning and Environmental Law, Vol.
                  58, No. 9 (American Planning Association, 2006).
                </li>
                <li>
                  Ferster, Andrea, Anticipatory Demolition: Tool for Protection
                  or Paper Tiger? Forum Journal, Vol. 26, No. 2, Winter 2012.
                </li>
              </ul>
            </div>
          </div>

          {/* Community Service */}
          <div className="block">
            <h2>Community Service</h2>
            <div className="block-sub">
              <ul>
                <li>
                  D.C. Bar, President (2013-14 term) D.C. Bar Treasurer (2010-11
                  term).
                </li>
                <li>D.C. Bar, Board of Governors (2011-2014 term).</li>{" "}
                <li>
                  D.C. Bar Practice Management Service Committee (2007 to
                  present).
                </li>{" "}
                <li>
                  Common Cause, National Governing Board Member, 1990-1996.
                </li>{" "}
                <li>Common Cause/D.C., Legal Counsel, 1984 to 1991.</li>{" "}
                <li>Common Cause/D.C., Chair, 1987 to 1989.</li>{" "}
                <li>
                  Trustee, Committee of 100 on the Federal City, 1996-2000
                  2004-2010.{" "}
                </li>
                <li>Founding board member, DC Refers.</li>
              </ul>
            </div>
          </div>

          {/* Awards and Honors */}
          <div className="block">
            <h2>Awards and Honors</h2>
            <div className="block-sub">
              <div className="p-block">
                <p>
                  2009 District of Columbia Awards for Excellence in Historic
                  Preservation, Historic Preservation Review Board Chairman's
                  Award for Excellence in Law and Public Policy{" "}
                </p>{" "}
                <p>2018 Fastcase 50 award honoree for innovation in the law</p>
                <p>Washington, DC SuperLawyers® 2014 to present</p>
              </div>
              <div className="img-block">
                <img src={superlawyer} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="main-content">
          {/* Contact */}
          <ContactBlock />
        </div>
      </div>
    </Layout>
  )
}

export default Qualifications
